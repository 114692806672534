<template>
  <div class="reply">
    <!--留言回复 -->
    <div class="total">
      全部留言(<span>{{ total }}</span
      >)
    </div>
    <div class="list" v-for="(item, index) in CommentList" :key="index">
      <div @click="gotoOtherspersonal(item)">
        <el-avatar :src="item.Avatar"></el-avatar>
      </div>
      <div class="right">
        <div class="text">
          <p>
            <span style="marginright: 5px">{{ item.Name }}:</span
            ><span style="color: #818181" v-html="item.Content"></span>
          </p>
          <el-image
            v-for="(n, dex) in item.Image"
            :key="dex"
            style="width: 100px; height: 100px; margin-top: 10px"
            :src="n"
            :preview-src-list="item.Image"
            fit="cover"
          ></el-image>
        </div>
        <div class="Replyfunction">
          <div class="replytime">
            <span
              >{{ item.time }}
              <span
                v-if="item.CommentReplyList.length != 0"
                @click="setminenewName(item)"
                >回复</span
              >
              <span
                v-if="item.MemberCode == windowMemberCode"
                style="margin-left: 5px"
                @click="delminenewName(item)"
                >删除</span
              ></span
            >
          </div>
          <div
            class="givethethumbs-up"
            v-if="item.CommenPraiseStatus == 0"
            @click="setCommenPraiseStatus(item, 0)"
          >
            <img src="../../../static/img/zan.png" alt="" />
            <span>{{ item.Praise }}</span>
          </div>
          <div
            class="givethethumbs-up"
            v-if="item.CommenPraiseStatus != 0"
            @click="setCommenPraiseStatus(item, 1)"
          >
            <img src="../../../static/img/zan_red.png" alt="" />
            <span>{{ item.Praise }}</span>
          </div>
        </div>
        <!-- 有回复列表的情况 -->
        <div class="Replylist" v-if="item.CommentReplyList.length != 0">
          <div
            class="textname"
            v-for="(data, ind) in item.CommentReplyList"
            :key="ind"
          >
            <span>{{ data.Name }}</span> 回复
            <span
              >{{ data.WasName }}：
              <span style="color: #818181" v-html="data.Content">{{
                data.Content
              }}</span>
            </span>
            <div>
              <el-image
                v-for="(i, ndex) in data.Image"
                :key="ndex"
                style="width: 100px; height: 100px; margin-top: 10px"
                :src="i"
                :preview-src-list="data.Image"
                fit="cover"
              ></el-image>
            </div>
            <div class="Replytime">
              <span
                >{{ data.CreatTime | formatDate }} ·
                <span @click="setnewName(item, data)">回复</span
                ><span
                  v-if="data.MemberCode == windowMemberCode"
                  style="margin-left: 5px"
                  @click="delnewName(item, data)"
                  >删除</span
                ></span
              >
            </div>
          </div>

          <div class="whitertextarea">
            <div class="textarea">
              <el-input placeholder="请输入内容" v-model="item.newform.Content">
                <template slot="prepend"
                  ><span class="prependspan"
                    >回复<span>{{ item.newName }}</span></span
                  ></template
                >
              </el-input>
            </div>

            <div class="expression">
              <div class="function">
                <el-popover placement="bottom">
                  <div class="functionemo">
                    <img
                      v-for="(n, ndex) in emoList"
                      :key="ndex"
                      :src="getImgUrl(n)"
                      alt=""
                      @click="newsetemo(item, n)"
                    />
                  </div>
                  <img
                    slot="reference"
                    src="../../../static/img/biaoqing.png"
                    alt=""
                  />
                </el-popover>
                <el-popover placement="bottom">
                  <el-upload
                    action="https://up-z2.qiniup.com/"
                    :data="postData"
                    list-type="picture-card"
                    :on-remove="
                      (file, fileList) => moreHandleRemove(file, fileList, item)
                    "
                    :on-success="newhandleAvatarSuccess"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <img
                    @click="getindex(index)"
                    slot="reference"
                    src="../../../static/img/oic.png"
                    alt=""
                  />
                </el-popover>
              </div>
              <div class="comment" @click="Secondaryreply(item)">回复</div>
            </div>
          </div>
          <div>
            <el-divider></el-divider>
            <div class="Replylisttotal">
              共{{ item.CommentReplyList.length }}条回复
            </div>
          </div>
        </div>
        <!-- 无回复列表的时候显示的回复框 -->
        <div class="newwhitertextarea" v-if="item.CommentReplyList.length == 0">
          <div class="textarea">
            <el-input placeholder="请输入内容" v-model="item.form.Content">
              <template slot="prepend"
                ><span class="prependspan"
                  >回复<span>{{ item.Name }}</span></span
                ></template
              >
            </el-input>
          </div>
          <!-- 表情等等 -->
          <div class="expression">
            <div class="function">
              <el-popover placement="bottom">
                <div class="functionemo">
                  <img
                    v-for="(n, ndex) in emoList"
                    :key="ndex"
                    :src="getImgUrl(n)"
                    alt=""
                    @click="setemo(item, n)"
                  />
                </div>
                <img
                  slot="reference"
                  src="../../../static/img/biaoqing.png"
                  alt=""
                />
              </el-popover>

              <el-popover placement="bottom">
                <el-upload
                  action="https://up-z2.qiniup.com/"
                  :data="postData"
                  list-type="picture-card"
                  :on-remove="
                    (file, fileList) => firstHandleRemove(file, fileList, item)
                  "
                  :on-success="handleAvatarSuccess"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <img
                  @click="getindex(index)"
                  slot="reference"
                  src="../../../static/img/oic.png"
                  alt=""
                />
              </el-popover>
            </div>
            <div
              class="comment"
              @click="AppletsFindFindCommentReplyInsert(item)"
            >
              回复
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import emo from "../../../static/js/emo.js";
import { formatDate } from "../../../static/js/formatDate";
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    CommentList: {
      type: Array,
      default: function () {
        return [
          {
            CommentReplyList: [],
          },
        ];
      },
    },
    postData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      type: 0,
      textarea: "",
      checked: true,
      emoList: [],
      index: 0,
      windowMemberCode: JSON.parse(window.localStorage.getItem("userinfo"))
        .MemberCode,
    };
  },
  created() {
    this.emoList = emo;
  },
  mounted() {},
  methods: {
    // 评论点赞
    setCommenPraiseStatus(item, index) {
      let obj = {
        token: window.localStorage.getItem("token"),
        Type: 3,
        CommentCode: item.CommentCode,
      };
      if (index == 0) {
        this.$api.common.FindPraiseInsert(obj).then((res) => {
          if (res.code == 200) {
            this.$emit("getPostdetails");
          }
        });
      } else {
        this.$api.common.FindPraiseDelete(obj).then((res) => {
          if (res.code == 200) {
            this.$emit("getPostdetails");
          }
        });
      }
    },
    // 下一页评论
    handleCurrentChange(val) {
      this.$emit("getPostdetails", val);
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
    // 切换成帖子一级回复人
    setminenewName(item) {
      item.newName = item.Name;
      item.newform.WasMemberCode = item.MemberCode;
    },
    // 删除评论
    delminenewName(item) {
      let obj = {
        token: window.localStorage.getItem("token"),
        CommentCode: item.CommentCode,
      };
      this.$api.common.FindCommentDelete(obj).then((res) => {
        if (res.code == 200) {
          this.$emit("getPostdetails");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 切换二级回复人
    setnewName(item, data) {
      item.newName = data.Name;
      item.newform.WasMemberCode = data.MemberCode;
    },
    // 删除帖子回复
    delnewName(item, data) {
      let obj = {
        token: window.localStorage.getItem("token"),
        CommentReplyCode: data.CommentReplyCode,
      };
      this.$api.common.FindCommentReplyDelete(obj).then((res) => {
        if (res.code == 200) {
          this.$emit("getPostdetails");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 记录form index
    getindex(index) {
      this.index = index;
    },
    //二级删除图片
    moreHandleRemove(file, fileList, item) {
      if (fileList.length > 0) {
        let str = "";
        let array = item.newform.Image.split(",");
        for (let index = 0; index < array.length; index++) {
          for (let n = 0; n < fileList.length; n++) {
            if (array[index] == fileList[n].response.key) {
              str = str + "," + fileList[n].response.key;
            }
          }
        }
        item.newform.Image = str;
      } else {
        item.newform.Image = "";
      }
    },
    // 一级删除图片
    firstHandleRemove(file, fileList, item) {
      if (fileList.length > 0) {
        let str = "";
        let array = item.form.Image.split(",");
        for (let index = 0; index < array.length; index++) {
          for (let n = 0; n < fileList.length; n++) {
            if (array[index] == fileList[n].response.key) {
              str = str + "," + fileList[n].response.key;
            }
          }
        }
        item.form.Image = str;
      } else {
        item.form.Image = "";
      }
    },
    // 一级上传图片成功
    handleAvatarSuccess(res, file) {
      this.CommentList[this.index].form.Image =
        this.CommentList[this.index].form.Image + "," + `${res.key}`;
    },
    // 二级级上传图片成功
    newhandleAvatarSuccess(res, file) {
      this.CommentList[this.index].newform.Image =
        this.CommentList[this.index].newform.Image + "," + `${res.key}`;
    },
    // 拼接表情
    getImgUrl(i) {
      return require("../../../static/emo/" + i.src);
    },
    // 二级回复插入表情
    newsetemo(item, n) {
      item.newform.Content = item.newform.Content + "[" + n.name + "]";
    },
    // 一级回复插入表情
    setemo(item, n) {
      item.form.Content = item.form.Content + "[" + n.name + "]";
    },
    // 有评论回复的时候回复评论
    Secondaryreply(item) {
      if (item.newform.Content == "") {
        this.$message.error("您还没有输入内容哦");
        return false;
      } else {
        if (item.newform.Image != "") {
          item.newform.Image = item.newform.Image.substr(1);
        }
        this.$api.common
          .AppletsFindFindCommentReplyInsert(item.newform)
          .then((res) => {
            if (res.code == 200) {
              item.newform.Content = "";
              item.newform.Image = "";
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$emit("getPostdetails");
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    //无评论回复的时候回复评论
    AppletsFindFindCommentReplyInsert(item) {
      if (item.form.Content == "") {
        this.$message.error("您还没有输入内容哦");
        return false;
      } else {
        if (item.form.Image != "") {
          item.form.Image = item.form.Image.substr(1);
        }
        this.$api.common
          .AppletsFindFindCommentReplyInsert(item.form)
          .then((res) => {
            if (res.code == 200) {
              item.form.Content = "";
              item.form.Image = "";
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$emit("getPostdetails");
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
};
</script>

<style lang='less' scoped>
.functionemo {
  width: 500px;
  img {
    cursor: pointer;
  }
}
/deep/.el-input__inner {
  border: 1px solid #e4d9c7 !important;
  border-left: none !important;
}

/deep/.el-input-group__append,
/deep/.el-input-group__prepend {
  background-color: #fff !important;
  border: 1px solid #e4d9c7 !important;
  border-right: none !important;
  padding: 0 !important;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 16px !important;
  font-family: NotoSansHans !important;
  font-weight: 300 !important;
  color: #818181 !important;
}
/deep/.el-checkbox__label {
  font-size: 16px !important;
  font-family: NotoSansHans !important;
  font-weight: 300 !important;
  color: #818181 !important;
}
/deep/ .el-checkbox__inner {
  width: 20px;
  height: 20px;
  border: 1px solid #818181;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border: 1px solid #e4d9c7 !important;
  background-color: #e4d9c7 !important;
}
/deep/[data-v-5be83c58] .el-checkbox__inner {
  border: 1px solid #818181 !important;
}
.prependspan {
  width: 71px;
  height: 13px;
  font-size: 14px;
  font-family: NotoSansHans;
  font-weight: 300;
  color: #818181;
  line-height: 24px;
  opacity: 0.8;
  span {
    color: #e6bf84;
  }
}
/deep/.el-divider--horizontal {
  margin: 10px 0px;
}
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e6bf84 !important;
}
.Replylisttotal {
  cursor: pointer;
  font-size: 14px;
  font-family: NotoSansHans;
  font-weight: 300;
  color: #b9a274;
  line-height: 24px;
}
.reply {
  width: 900px;
  // min-height: 780px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 60px;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 0;
  .total {
    width: 100%;
    font-size: 18px;
    font-family: NotoSansHans;
    font-weight: normal;
    color: #818181;
    line-height: 49px;
    border-bottom: 1px solid #f6f6f6;
    span {
      color: #e6bf84;
    }
  }
  .list {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 20px 0px;
    border-bottom: 1px solid #f6f6f6;
    .newwhitertextarea {
      width: 100%;
      // height: 97px;
      margin-top: 20px;
      // background: #ffffff;
      box-sizing: border-box;
      .textarea {
        width: 100%;
        display: flex;

        * {
          margin-left: 10px;
        }
      }

      .expression {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 10px;
        padding-right: 0;

        .function {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-around;

          * {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .comment {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 26px;
          background: #e6bf84;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
          font-family: NotoSansHans;
          font-weight: 300;
          color: #ffffff;
        }
      }
    }
    .right {
      width: 100%;
      .text {
        margin-left: 10px;
        font-size: 14px;
        line-height: 24px;
        font-family: NotoSansHans;
        font-weight: 300;
        color: #818181;
        opacity: 0.8;

        p {
          margin: 0;
          padding: 0;
          padding-top: 5px;
        }
        span {
          color: #e6bf84;
        }
      }
      .Replyfunction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .replytime {
          font-size: 13px;
          font-family: NotoSansHans;
          font-weight: 100;
          margin-left: 10px;
          line-height: 49px;
          opacity: 0.8;
          span {
            color: rgba(129, 129, 129, 0.8);
            span {
              color: #1b55a5;
              cursor: pointer;
            }
          }
        }
        .givethethumbs-up {
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 18px;
            height: 18px;
          }
          span {
            margin-left: 5px;

            font-size: 13px;
            font-family: NotoSansHans;
            font-weight: 100;
            color: #818181;
          }
        }
      }
      .Replylist {
        width: 100%;
        // min-height: 296px;
        background: #f8f8f8;
        box-sizing: border-box;
        padding: 10px;
        .textname {
          width: 100%;
          font-size: 14px;
          font-family: NotoSansHans;
          font-weight: 300;
          color: #818181;
          line-height: 24px;
          opacity: 0.8;
          span {
            color: #e6bf84;
          }
          .Replytime {
            width: 100%;
            display: flex;
            box-sizing: border-box;
            padding-left: 20px;
            span {
              font-size: 13px;
              font-family: NotoSansHans;
              font-weight: 100;
              color: #818181;
              opacity: 0.8;
              span {
                color: #1b55a5;
                cursor: pointer;
              }
            }
          }
        }
        .whitertextarea {
          width: 100%;
          // height: 97px;
          margin-top: 20px;
          background: #ffffff;
          box-sizing: border-box;
          padding: 10px;
          .textarea {
            width: 100%;
            display: flex;

            * {
              margin-left: 10px;
            }
          }

          .expression {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 10px;
            padding-right: 0;

            .function {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-around;

              * {
                margin-left: 10px;
                cursor: pointer;
              }
            }

            .comment {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 26px;
              background: #e6bf84;
              border-radius: 4px;
              font-size: 14px;
              cursor: pointer;
              font-family: NotoSansHans;
              font-weight: 300;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
