<template>
  <div class="Postdetails">
    <div>
      <div class="Post">
        <div class="user">
          <div class="msg" @click="gotoOtherspersonal(data.FindData)">
            <!-- <img class="Avatar" :src="data.FindData.Avatar" alt="" /> -->
            <div class="avatar" @click="gotoOtherspersonal(item)">
              <img :src="data.FindData.Avatar" />
              <div class="Grade" v-if="data.FindData.Gender == '女'">
                {{ data.FindData.Grade }}
              </div>
              <div class="Grademan" v-if="data.FindData.Gender == '男'">
                {{ data.FindData.Grade }}
              </div>
            </div>

            <div class="details">
              <div class="name">
                <div class="titlename">{{ data.FindData.Name }}</div>

                <img
                  class="lvip"
                  v-if="data.FindData.BigV == 1"
                  src="../../static/img/lvip.png"
                  alt=""
                />
                <img
                  class="lvip"
                  v-if="data.FindData.BigV == 2"
                  src="../../static/img/hvip (1).png"
                  alt=""
                />
              </div>
              <div class="time" v-if="data.FindData.CreatTime">
                {{ data.FindData.CreatTime | formatDate }}
              </div>
            </div>
          </div>
          <div class="operation">
            <!-- <img class="zhuanfa" src="../../static/img/zhuanfa.png" alt="" />
            <el-dropdown>
              <span class="span">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>举报</el-dropdown-item>
                <el-dropdown-item divided>关注</el-dropdown-item>
                <el-dropdown-item divided>拉黑</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
        </div>
        <div class="forward">
          <span v-if="data.FindData.ForwardedName" style="color: #818181"
            >转发:<span v-html="data.FindData.ForwardedText"></span
            ><br /><br />原文//<span
              style="color: #e6bf84; cursor: pointer"
              @click="gotoOtherspersonalweb(data.FindData.ForwardedMembeCode)"
              >@{{ data.FindData.ForwardedName }}</span
            >:</span
          >
        </div>
        <div class="text">
          <span>
            <span
              style="cursor: pointer"
              v-if="data.FindData.Title"
              @click="gotoconversation(data.FindData)"
            >
              #{{ data.FindData.Title }}#</span
            >

            <div
              v-if="data.FindData.DryGoods != 1"
              v-html="data.FindData.Content"
            >
              <!-- {{ data.FindData.Content}} -->
            </div>
            <div
              class="DryGoodsbox"
              v-if="data.FindData.DryGoods == 1"
              v-html="data.FindData.DryGoodsContent"
            ></div>
          </span>
        </div>
        <div class="video" v-if="data.FindData.Video">
          <video controls :src="data.FindData.Video"></video>
        </div>
        <div class="image" v-if="data.FindData.DryGoods != 1">
          <el-image
            class="imagebox"
            :src="item"
            fit="cover"
            v-for="(item, index) in data.FindData.Image"
            :key="index"
            :preview-src-list="data.FindData.Image"
          ></el-image>
        </div>
        <div class="thumbs-up">
          <div class="box">
            <img
              src="../../static/img/qihong.png"
              @click="FindPraiseInsert(data.FindData, 0)"
              alt=""
            />
            <span>起哄({{ data.FindData.Tread }})</span>
          </div>
          <div class="box">
            <img
              src="../../static/img/hecai.png"
              v-if="data.FindData.PraiseStatus != 0"
              @click="FindPraiseInsert(data.FindData, 1)"
              alt=""
            />
            <img
              v-if="data.FindData.PraiseStatus == 0"
              src="../../static/img/hecai2.png"
              @click="FindPraiseInsert(data.FindData, 1)"
              alt=""
            />
            <span
              >喝彩(<span>{{ data.FindData.Praise }}</span
              >)</span
            >
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 点赞人群 -->
        <div class="avatarboos">
          <div class="box">
            <img
              class="elavatar"
              v-for="(item, index) in data.FindData.FindDislikeList"
              :key="index"
              :src="item.Avatar"
              @click="gotoOtherspersonal(item)"
            />
          </div>
          <div class="box">
            <img
              class="elavatar"
              v-for="(item, index) in data.FindData.FindPraiseList"
              :key="index"
              :src="item.Avatar"
              @click="gotoOtherspersonal(item)"
            />
          </div>
        </div>
        <!-- <el-divider></el-divider> -->
        <!-- 输入框 -->
        <div class="textarea">
          <el-avatar :src="Avatar"></el-avatar>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="AppletsFindCommentInsert.Content"
          >
          </el-input>
        </div>
        <!-- 表情等等 -->
        <div class="expression">
          <div class="function">
            <!-- 表情 -->
            <el-popover placement="bottom">
              <div class="functionemo">
                <img
                  v-for="(item, index) in emoList"
                  :key="index"
                  :src="getImgUrl(item)"
                  alt=""
                  @click="setemo(item)"
                />
              </div>
              <img
                slot="reference"
                src="../../static/img/biaoqing.png"
                alt=""
              />
            </el-popover>
            <!-- 图片 -->
            <el-popover placement="bottom">
              <el-upload
                action="https://up-z2.qiniup.com/"
                :data="postData"
                list-type="picture-card"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <img slot="reference" src="../../static/img/oic.png" alt="" />
            </el-popover>
            <el-checkbox v-model="checked"
              ><span style="fontsize: 14px">同时转发</span></el-checkbox
            >
          </div>
          <div class="comment" @click="setAppletsFindCommentInsert">评论</div>
        </div>
      </div>
      <Reply
        :total="data.total"
        :CommentList="data.CommentList"
        :postData="postData"
        v-if="data.CommentList.length > 0"
        @getPostdetails="getPostdetails"
      />
    </div>

    <div class="recommend">
      <Mine :mine="mine" @getPostdetails="getPostdetails" />
      <Timemachine :list="HobbiesFindList" />
    </div>
  </div>
</template>

<script>
import Mine from "./common/mine.vue";
import Timemachine from "./common/time.vue";
import Reply from "./common/reply.vue";
import { formatDate } from "../../static/js/formatDate";
import emo from "../../static/js/emo.js";
const brst_url = "https://adn.xsdyiwu.com/";
export default {
  data() {
    return {
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      textarea: "",
      checked: false,
      form: {
        FindCode: "",
        token: window.localStorage.getItem("token"),
        pageIndex: 1,
        pageSize: 10,
        AudioQuery: "1",
      },
      dialogImageUrl: "",
      HobbiesFindList: [],
      HobbiesFindListtype: 1,
      data: {
        FindData: {
          Avatar: "",
        },
        CommentList: [
          {
            Name: "",
            Content: "",
            CommentReplyList: [
              {
                Content: "",
                Image: [],
                Name: "",
              },
            ],
          },
        ],
      },
      mine: {
        MemberData: {
          Avatar: "",
        },
      },
      emoList: [],
      Avatar: window.localStorage.getItem("Avatar") || "",
      AppletsFindCommentInsert: {
        token: window.localStorage.getItem("token"),
        FindCode: "",
        Content: "",
        Forwarded: 1,
        Image: "",
      }, //发表帖子评论表单
      postData: {
        token: "", //七牛云上传token
        region: "SCN",
      },
    };
  },
  components: {
    Mine,
    Timemachine,
    Reply,
  },
  created() {
    this.getPostdetails();
    this.emoList = emo;
    this.qny_think();
  },
  methods: {
    // 格式化contern
    formatcontern(val) {
      let html = val
        .replace(/\<img/gi, '<img class="rich-img" ')
        .replace(
          /\[emoji(.+?)\]/gi,
          "<img class='icon-emoji-item' style='width:15px;height:15px' src='https://www.xsdyiwu.com/emoji/emoji$1.png' />"
        )
        .replace(/\<p/gi, '<p class="rich-p"')
        .replace(/\<span/gi, '<span class="rich-span"');
      // html = html.replaceAll("\n", "<br/>");
      html = html.replace(new RegExp("\n", "gm"), "<br/>");
      return html;
    },
    // 去话题
    gotoconversation(item) {
      this.$router.push(`conversation?topic=${item.Title}`);
    },
    // 帖子踩踏/点赞 0踩踏 1点赞
    FindPraiseInsert(data, index) {
      let obj;
      if (index == 0) {
        obj = {
          token: window.localStorage.getItem("token"),
          Type: 2,
          FindCode: data.FindCode,
        };
      } else {
        obj = {
          token: window.localStorage.getItem("token"),
          Type: 1,
          FindCode: data.FindCode,
        };
      }
      if (data.PraiseStatus == 0) {
        this.$api.common.FindPraiseInsert(obj).then((res) => {
          if (res.code == 200) {
            this.getPostdetails();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      } else {
        this.$api.common.FindPraiseDelete(obj).then((res) => {
          if (res.code == 200) {
            this.getPostdetails();
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      }
    },
    // 上传图片成功
    handleAvatarSuccess(res, file) {
      this.AppletsFindCommentInsert.Image =
        this.AppletsFindCommentInsert.Image + "," + `${res.key}`;
    },
    //删除图片
    handleRemove(file, fileList) {
      if (fileList.length > 0) {
        let str = "";
        let array = this.AppletsFindCommentInsert.Image.split(",");
        for (let index = 0; index < array.length; index++) {
          for (let n = 0; n < fileList.length; n++) {
            if (array[index] == fileList[n].response.key) {
              str = str + "," + fileList[n].response.key;
            }
          }
        }
        this.AppletsFindCommentInsert.Image = str;
      } else {
        this.AppletsFindCommentInsert.Image = "";
      }
    },
    // 发布评论
    setAppletsFindCommentInsert() {
      if (this.AppletsFindCommentInsert.Content == "") {
        this.$message({
          message: "内容不能为空",
          type: "warning",
        });
        return false;
      } else {
        if (this.AppletsFindCommentInsert.Image != "") {
          this.AppletsFindCommentInsert.Image =
            this.AppletsFindCommentInsert.Image.substr(1);
        }

        if (this.checked) {
          this.AppletsFindCommentInsert.Forwarded = 1;
        } else {
          this.AppletsFindCommentInsert.Forwarded = 0;
        }
        this.$api.common
          .AppletsFindCommentInsert(this.AppletsFindCommentInsert)
          .then((res) => {
            if (res.code == 200) {
              this.AppletsFindCommentInsert = {
                token: window.localStorage.getItem("token"),
                FindCode: "",
                Content: "",
                Forwarded: 1,
              };
              this.getPostdetails();
            }
          });
      }
    },
    // 插入表情
    setemo(item) {
      this.AppletsFindCommentInsert.Content =
        this.AppletsFindCommentInsert.Content + "[" + item.name + "]";
    },
    // 拼接表情
    getImgUrl(i) {
      return require("../../static/emo/" + i.src);
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
    gotoOtherspersonalweb(item) {
      this.$router.push(`Otherspersonal?id=${item}`);
    },
    // 帖子详情
    getPostdetails(index) {
      if (index) {
        this.form.pageIndex = index;
      }
      if (this.$route.query.FindCode) {
        this.form.FindCode = this.$route.query.FindCode;
        this.AppletsFindCommentInsert.FindCode = this.$route.query.FindCode;
      }
      this.$api.common.FindDetail(this.form).then((res) => {
        if (res.code == 200) {
          for (const key in res.data) {
            if (key == "CommentList") {
              for (let index = 0; index < res.data[key].length; index++) {
                // 第一次评论表单
                res.data[key][index].Content = this.RichContent(
                  res.data[key][index].Content
                );
                res.data[key][index].form = {
                  token: window.localStorage.getItem("token"),
                  Content: "",
                  WasMemberCode: res.data[key][index].MemberCode,
                  Image: "",
                  CommentCode: res.data[key][index].CommentCode,
                };
                // 第二次回复表单
                res.data[key][index].newform = {
                  token: window.localStorage.getItem("token"),
                  Content: "",
                  WasMemberCode: res.data[key][index].MemberCode,
                  Image: "",
                  CommentCode: res.data[key][index].CommentCode,
                };
                res.data[key][index].newName = res.data[key][index].Name;
                for (
                  let n = 0;
                  n < res.data[key][index].CommentReplyList.length;
                  n++
                ) {
                  res.data[key][index].CommentReplyList[n].Content =
                    this.RichContent(
                      res.data[key][index].CommentReplyList[n].Content
                    );
                }
              }
            }
          }
          if (res.data.FindData.DryGoodsContent != null) {
            res.data.FindData.DryGoodsContent = this.RichContent(
              res.data.FindData.DryGoodsContent
            );
          }
          if (res.data.FindData.Content != null) {
            res.data.FindData.Content = this.formatcontern(
              res.data.FindData.Content
            );
          }
          if (res.data.FindData.ForwardedText != null) {
            res.data.FindData.ForwardedText = this.formatcontern(
              res.data.FindData.ForwardedText
            );
          }

          this.data = res.data;
          // data.FindData.DryGoodsContent

          if (this.HobbiesFindListtype == 1) {
            for (
              let index = 0;
              index < res.data.HobbiesFindList.length;
              index++
            ) {
              res.data.HobbiesFindList[index].Content = this.RichContent(
                res.data.HobbiesFindList[index].Content
              );
            }
            this.HobbiesFindList = res.data.HobbiesFindList;
          }
          this.HobbiesFindListtype = 2;
          this.$api.common
            .MemberInfo({
              MemberCode: res.data.FindData.MemberCode,
              token: window.localStorage.getItem("token"),
            })
            .then((res) => {
              if (res.code == 200) {
                this.mine = res.data;
              }
            });
        } else {
          this.$message.error("详情出错");
        }
      });
    },
    // 七牛云上传token
    qny_think() {
      this.$api.common
        .qny_think({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.postData.token = res.data.token;
          }
        });
    },
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
.functionemo {
  width: 500px;
  img {
    cursor: pointer;
  }
}
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
/deep/.el-textarea__inner {
  border: 1px solid #e4d9c7 !important;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 16px !important;
  font-family: NotoSansHans !important;
  font-weight: 300 !important;
  color: #818181 !important;
}
/deep/.el-checkbox__label {
  float: right;
  font-size: 16px !important;
  font-family: NotoSansHans !important;
  font-weight: 300 !important;
  color: #818181 !important;
  padding-left: 0 !important;
}
/deep/ .el-checkbox__inner {
  border: 1px solid #818181 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  margin-top: -7px;
  height: 17px;
  width: 17px;
  margin-left: 12px;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border: 1px solid #e4d9c7 !important;
  background-color: #e6bf84 !important;
}
/deep/[data-v-5be83c58] .el-checkbox__inner {
  border: 1px solid #818181 !important;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  margin: 1px 0px 0px 2px;
}
// /deep/ .el-checkbox__input {
//   float: left;
//   height: 17px;
//   width: 17px;
// }
</style>
