<template>
  <div class="time">
    <div class="title">时光机</div>
    <el-divider></el-divider>
    <div class="list" v-for="(item, index) in list" :key="index">
      <div class="name" @click="gotoOtherspersonal(item)">{{ item.Name }}</div>
      <div class="timeflow">
        <div class="timeflowleft">{{ item.CreatTime | formatDate }}</div>
        <div class="timeflowright">
          <!-- <img src="../../../static/img/liulan2.png" alt="" /> -->
          <!-- <span>1209</span> -->
        </div>
      </div>
      <div class="text">
        <span></span>
        <span
          style="color: #818181"
          @click="gotoPostdetails(item)"
          v-html="msg(item.Content)"
          >{{ item.Content | string }}</span
        >
      </div>
      <div class="imgboss">
        <img
          :src="i"
          alt=""
          @click="gotoPostdetails(item)"
          v-for="(i, ind) in item.Image"
          :key="ind"
        />
      </div>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
import { formatDate } from "../../../static/js/formatDate";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 去帖子详情
    gotoPostdetails(item) {
      // this.$router.push();
      console.log(312312312);
      window.location.href = `http://www.yiwuwenwan.com/#/Postdetails?FindCode=${item.FindCode}`;
      window.document.location.reload();
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
  },
  filters: {
    string(val) {
      if (val.length > 28) {
        let str = val.substr(0, 28);
        str = str + "...";
        return str;
      } else {
        return val;
      }
    },
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
};
</script>

<style lang='less' scoped>
.time {
  width: 283px;
  min-height: 555px;
  background: #ffffff;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
  .title {
    font-size: 20px;
    font-family: NotoSansHans;
    font-weight: 520;
    color: #818181;
  }
  /deep/.el-divider--horizontal {
    margin: 10px 0px;
  }
  /deep/.el-divider--horizontal {
    background-color: #f8f8f8;
  }
  .list {
    width: 100%;
    margin-bottom: 20px;
    .name {
      font-size: 16px;
      font-family: NotoSansHans;
      font-weight: 300;
      color: #1d1d1d;
      cursor: pointer;
    }
    .timeflow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .timeflowleft {
        font-size: 12px;
        font-family: NotoSansHans;
        font-weight: 100;
        color: #818181;
        opacity: 0.8;
      }
      .timeflowright {
        img {
          width: 16px;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          font-family: NotoSansHans;
          font-weight: 100;
          color: #818181;
          opacity: 0.6;
        }
      }
    }
    .text {
      width: 100%;
      font-size: 13px;
      font-family: NotoSansHans;
      font-weight: 100;
      color: #818181;
      cursor: pointer;
      margin-top: 10px;
      span {
        color: #e6bf84;
        cursor: pointer;
        line-height: 23px;
      }
    }
    .imgboss {
      width: 100%;
      margin-top: 10px;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
        margin-right: 10px;
      }
      img:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
}
</style>
